<template>
  <LxpMobileHeader title="업무별 연락처" />
  <main class="kb-main" id="kb-helpdesk-contact">
    <div class="main-content main-component">
      <div class="hrdt-container">
        <div class="contact-container view-component">


          <article v-for="(team,idx) in contactList" class="content-section" :key="idx">
            <header class="section-header header-divider">
              <h3 class="title">{{ team.title }}</h3>
            </header>
            <div class="contact-tablelist">
              <div class="kb-table kb-table-bordered">
                <table>
                  <colgroup>
                    <col style="width:auto;">
                    <col style="width:80px;">
                    <col style="width:100px;">
                  </colgroup>
                  <tbody>
                  <tr v-for="(member, midx) in team.member" :key="midx">
                    <td><span class="td-text" :class="{ 'kb-cell-text-bold' : member.leaderdiv!='M'}">{{ member.job_title }}</span></td>
                    <td><a><strong class="td-text">{{ member.name }}</strong></a></td>
                    <td><div class="td-actions"><a :href="`tel:${member.telno}`" class="kb-btn kb-btn-secondary kb-btn-sm rounded-lg" type="button"><i class="icon-call"></i><span class="text">{{ member.telno_view }}</span></a></div></td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </article>



        </div>
      </div>
    </div>
    <!-- //main-content -->
  </main>
  <!-- end::kb-main -->
</template>

<script>
import LxpMobileHeader from "@/views/layout/lxp/header/mobile/LxpMobileHeader";
import {useStore} from "vuex";
import {computed} from "vue";

export default {
  name: 'HelpMobileContact',
  components: {LxpMobileHeader},
  setup(){

    const store = useStore();
    const contactList = computed(() =>{ return store.state.help.contactList });

    return {
      contactList
    }
  }
};
</script>